// @flow
import React, {
  type StatelessFunctionalComponent,
  type Node,
} from "react";
import {
  Box,
  Button,
  IconButton,
  type Theme,
} from "@mui/material";
import {
  MoreHoriz,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { makeStylesTyped } from "@fas/cpa-cabinet-ui/lib/helpers";
import range from "lodash.range";

type Props = {
  count: number,
  page: number,
  rowsPerPage: number,
  onPageChange: (SyntheticMouseEvent<HTMLButtonElement>, number) => void,
}

type Classes = {
  root: string,
  button: string,
}

const useStyles: () => Classes = makeStylesTyped((theme: Theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
    color: theme.palette.primary.main,
    height: "50px",
    width: "50px",
    minWidth: "50px",
  },
}));

const getDisplayedButtons: (pagesCount: number, page: number) => number[] = (pagesCount, page) => {
  if (pagesCount < 6) return range(0, pagesCount);

  const bottomLine: number = Math.max(1, page - 2);
  const upperLine: number = Math.min(pagesCount, page + 3);

  return range(bottomLine, upperLine);
};

const TablePaginationActions: StatelessFunctionalComponent<Props> = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
}: Props) => {
  const classes: Classes = useStyles();

  const pagesCount: number = Math.max(0, Math.ceil(count / rowsPerPage) - 1);

  const handleFirstPageButtonClick: (event: SyntheticMouseEvent<HTMLButtonElement>) => void = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick: (event: SyntheticMouseEvent<HTMLButtonElement>) => void = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick: (event: SyntheticMouseEvent<HTMLButtonElement>) => void = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick: (event: SyntheticMouseEvent<HTMLButtonElement>) => void = (event) => {
    onPageChange(event, pagesCount);
  };

  const handlePageButtonClick: (
    event: SyntheticMouseEvent<HTMLButtonElement>,
    pageNumber: number
  ) => void = (event, pageNumber) => {
    onPageChange(event, pageNumber);
  };

  const buttonIndeces: number[] = getDisplayedButtons(pagesCount, page);

  return (
    <Box className={classes.root}>
      <IconButton className={classes.button} onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
      >
        1
      </Button>
      {buttonIndeces.map((pageIndex: number, index: number): Node => (
        <React.Fragment key={pageIndex}>
          {index === 0 && pageIndex > 1 && (
            <IconButton className={classes.button} disabled>
              <MoreHoriz />
            </IconButton>
          )}
          <Button
            key={pageIndex}
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={(event) => handlePageButtonClick(event, pageIndex)}
            disabled={page === pageIndex}
          >
            {pageIndex + 1}
          </Button>
          {index === buttonIndeces.length - 1 && pageIndex < pagesCount - 1 && (
            <IconButton className={classes.button} disabled>
              <MoreHoriz />
            </IconButton>
          )}
        </React.Fragment>
      ))}
      {pagesCount > 0 && (
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={handleLastPageButtonClick}
          disabled={page === pagesCount}
        >
          {pagesCount + 1}
        </Button>
      )}
      <IconButton className={classes.button} onClick={handleNextButtonClick} disabled={page >= pagesCount} aria-label="next page">
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  );
};

export default TablePaginationActions;
