// @flow
import React, {
  type StatelessFunctionalComponent,
  type Node,
} from "react";
import {
  Box,
  Typography,
  type Theme,
} from "@mui/material";
import type { Filters } from "@fas/cpa-state-manager/redux/actions/table/actions";
import { makeStylesTyped } from "@fas/cpa-cabinet-ui/lib/helpers";
import type { ItemData, TotalData } from "../../services/dashboardApi";
import type { Column } from "../TableComponent/types/TableComponent.types";
import { TRANSACTION_TABLE } from "../../helpers/constants";
import { TableComponent, TablePagination } from "../TableComponent";

type Props = {
  data: ItemData[],
  totalData: TotalData,
  page: number,
  total: number,
  pageSize: number,
  fields: string[],
  filters: Filters,
  onChangeTablePage: (string, number) => mixed,
  onGetData: () => mixed,
}

type Classes = {
  row: string,
  headerCell: string,
  dateCell: string,
  commissionCell: string,
}

const useStyles: () => Classes = makeStylesTyped((theme: Theme) => ({
  row: {
    "&:last-of-type": {
      height: "40px",
      backgroundColor: theme.palette.success.main,
    },
    "&:last-of-type td": {
      fontWeight: 600,
      color: theme.palette.primary.light,
    },
    "&:last-of-type:hover": {
      backgroundColor: theme.palette.success.main,
      opacity: 0.9,
    },
  },
  headerCell: {
    color: theme.palette.primary.main,
  },
  dateCell: {
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: "5px",
    backgroundColor: theme.palette.background.default,
  },
  commissionCell: {
    color: theme.palette.success.main,
  },
}));

const TransactionReportTable: StatelessFunctionalComponent<Props> = ({
  data,
  totalData,
  page,
  total,
  pageSize,
  fields,
  filters,
  onChangeTablePage,
  onGetData,
}: Props) => {
  const classes: Classes = useStyles();

  const columns: Array<Column<ItemData>> = [
    {
      label: "Date",
      field: "date",
      key: "date",
      render: ({ row: { date } }): Node => (
        <Box className={classes.dateCell}>{date}</Box>
      ),
    },
    {
      label: "Company Name",
      field: "customer",
      key: "customer",
    },
    {
      label: "Transaction Id",
      field: "invoiceId",
      key: "invoiceId",
    },
    {
      label: "Department",
      field: "department",
      key: "department",
    },
    {
      label: "Country",
      field: "country",
      key: "country",
    },
    {
      label: "Platform",
      field: "platform",
      key: "platform",
    },
    {
      label: "Age Group",
      field: "age",
      key: "age",
    },
    {
      label: "Leads",
      field: "quantity",
      key: "quantity",
    },
    {
      label: "Commission",
      field: "cost",
      key: "cost",
      render: ({ row: { cost } }): Node => (
        <Typography className={classes.commissionCell}>{cost ? `${cost}` : ""}</Typography>
      ),
    },
    {
      label: "Total Commission",
      field: "amount",
      key: "amount",
      render: ({ row: { amount } }: { row: ItemData }): Node => `${String(filters.currency) || "$"} ${parseFloat(amount).toFixed(2)}`,
    },
  ].filter((column: Column<ItemData>): boolean => fields.includes(column.field));

  const totalColumns: Array<Column<TotalData>> = [
    {
      label: "",
      field: "",
      key: "summary",
      render: (): string => "Summary",
    },
    {
      label: "",
      field: "",
      key: "amount",
      render: ({ row }: { row: TotalData }): string => `€ ${row.amount}`,
      colSpan: columns.length - 1,
    },
  ];

  const handlePageChange = (newPage: number) => {
    onChangeTablePage(TRANSACTION_TABLE, newPage);
    onGetData();
  };

  return (
    <>
      <TableComponent
        classes={{
          headerCell: classes.headerCell,
          row: classes.row,
        }}
        data={data}
        columns={columns}
        totalData={totalData}
        totalColumns={totalColumns}
      />
      <TablePagination
        data-testid="pagination"
        page={page}
        pageSize={pageSize}
        count={total}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default TransactionReportTable;
