// @flow
import React, {
  type Node,
  type StatelessFunctionalComponent,
} from "react";
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  type TextFieldProps,
  type Theme,
} from "@mui/material";
import {
  Stars,
  LayersOutlined,
  MoreVert,
  PhoneAndroid,
} from "@mui/icons-material";
import type {
  Filters,
  ChangeTableFilters,
} from "@fas/cpa-state-manager/redux/actions/table";
import {
  FilterBuilder,
} from "@fas/cpa-cabinet-ui";
import { makeStylesTyped } from "@fas/cpa-cabinet-ui/lib/helpers";
import { BRANDS_TABLE } from "../../helpers/constants";
import { mapOptions } from "../../helpers/generators";
import type { GetActiveBrandsSaga } from "../../actions/activeBrands";
import type { DropDownObjItemType } from "../../reducers/dictionaries";

type Props = {
  filters: Filters,
  domainsDropdown: Array<DropDownObjItemType>,
  verticalsDropdown: Array<DropDownObjItemType>,
  nichesDropdown: Array<DropDownObjItemType>,
  platformsDropdown: Array<DropDownObjItemType>,
  loading: boolean,
  dropdownLoading: boolean,
  onChangeTableFilters: (table: string, filters:Filters) => ChangeTableFilters,
  onGetActiveBrandsData: () => GetActiveBrandsSaga,
}

type Classes = {
  label: string,
  input: string,
}

const useStyles: () => Classes = makeStylesTyped((theme: Theme) => ({
  label: {
    fontWeight: 600,
    paddingBottom: theme.spacing(1),
  },
  input: {
    width: "253px",
  },
}));

const FilterWrapper: StatelessFunctionalComponent<*> = ({ children }) => (
  <Box display="inline-block" pr={1} pb={1}>{children}</Box>
);

const ActiveBrandsFilters: StatelessFunctionalComponent<Props> = ({
  filters,
  domainsDropdown,
  verticalsDropdown,
  nichesDropdown,
  platformsDropdown,
  loading,
  dropdownLoading,
  onChangeTableFilters,
  onGetActiveBrandsData,
}: Props) => {
  const classes: Classes = useStyles();

  return (
    <Box display="flex">
      <Box flexGrow={1}>
        {/* FIXME: fix filters and onFiltersChange types */}
        <FilterBuilder
          // $FlowFixMe
          filters={filters}
          // $FlowFixMe
          onFiltersChange={(newFilters): $PropertyType<Props, "onChangeTableFilters"> => onChangeTableFilters(BRANDS_TABLE, newFilters)}
          WrapperItemComponent={FilterWrapper}
          items={[
            {
              type: "select",
              filterKey: "domain",
              filterProps: {
                label: "Brand Name",
                className: classes.input,
                classes: { formLabel: classes.label },
                disabled: loading,
                loading: dropdownLoading,
                "data-testid": "filter-select-domain",
                options: [
                  { value: "", title: "All" },
                  ...domainsDropdown.map(({ label }) => ({ value: label, title: label })),
                ],
                renderInput: ({ InputProps, ...rest }: TextFieldProps): Node => (
                  <TextField
                    {...rest}
                    variant="filled"
                    InputProps={{
                      ...InputProps,
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start" variant="standard">
                          <Stars />
                        </InputAdornment>
                      ),
                    }}
                  />
                ),
              },
            },
            {
              type: "select",
              filterKey: "vertical",
              filterProps: {
                label: "Verticals",
                className: classes.input,
                classes: { formLabel: classes.label },
                disabled: loading,
                loading: dropdownLoading,
                "data-testid": "filter-select-vertical",
                options: [
                  { value: "", label: "All" },
                  ...verticalsDropdown,
                ]
                  .map(mapOptions),
                renderInput: ({ InputProps, ...rest }: TextFieldProps): Node => (
                  <TextField
                    {...rest}
                    variant="filled"
                    InputProps={{
                      ...InputProps,
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start" variant="standard">
                          <LayersOutlined />
                        </InputAdornment>
                      ),
                    }}
                  />
                ),
              },
            },
            {
              type: "select",
              filterKey: "niche",
              filterProps: {
                label: "Niche",
                className: classes.input,
                classes: { formLabel: classes.label },
                disabled: loading,
                loading: dropdownLoading,
                "data-testid": "filter-select-niche",
                options: [
                  { value: "", label: "All" },
                  ...nichesDropdown,
                ]
                  .map(mapOptions),
                renderInput: ({ InputProps, ...rest }: TextFieldProps): Node => (
                  <TextField
                    {...rest}
                    variant="filled"
                    InputProps={{
                      ...InputProps,
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start" variant="standard">
                          <MoreVert />
                        </InputAdornment>
                      ),
                    }}
                  />
                ),
              },
            },
            {
              type: "select",
              filterKey: "platform",
              filterProps: {
                label: "Platform",
                className: classes.input,
                classes: { formLabel: classes.label },
                disabled: loading,
                loading: dropdownLoading,
                "data-testid": "filter-select-platform",
                options: [
                  { value: "", label: "All" },
                  ...platformsDropdown,
                ]
                  .map(mapOptions),
                renderInput: ({ InputProps, ...rest }: TextFieldProps): Node => (
                  <TextField
                    {...rest}
                    variant="filled"
                    InputProps={{
                      ...InputProps,
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start" variant="standard">
                          <PhoneAndroid />
                        </InputAdornment>
                      ),
                    }}
                  />
                ),
              },
            },
          ]}
        />
      </Box>
      <Box
        width="220px"
        pt={2}
      >
        <Button
          fullWidth
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={onGetActiveBrandsData}
        >
          Apply filters
        </Button>
      </Box>
    </Box>
  );
};

export default ActiveBrandsFilters;
