// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import {
  Box,
  Button,
  IconButton,
  type Theme,
} from "@mui/material";
import {
  Clear,
} from "@mui/icons-material";
import { makeStylesTyped } from "@fas/cpa-cabinet-ui/lib/helpers";
import type { DateRangeFooterProps } from "@fas/cpa-cabinet-ui/lib/DateRangePicker/DateRangePicker.types";

type Classes = {
  apply: string,
  cancel: string,
}

const useStyles = makeStylesTyped((theme: Theme) => ({
  apply: {
    flexGrow: 1,
  },
  cancel: {
    width: "50px",
    marginRight: theme.spacing(1),
  },
}));

const FooterComponent: StatelessFunctionalComponent<DateRangeFooterProps> = ({
  onClose,
  onApply,
}: DateRangeFooterProps) => {
  const classes: Classes = useStyles();

  return (
    <Box p={2} display="flex" justifyContent="flex-end">
      <Box flexGrow={3} />
      <IconButton onClick={onClose} className={classes.cancel} size="large">
        <Clear />
      </IconButton>
      <Button
        size="large"
        color="primary"
        variant="contained"
        onClick={onApply}
        className={classes.apply}
      >
        Apply
      </Button>
    </Box>
  );
};

export default FooterComponent;
