// @flow
import React, {
  type StatelessFunctionalComponent,
  type Element,
  type Node,
} from "react";
import {
  Button,
  type Theme,
} from "@mui/material";
import {
  FileCopyOutlined,
  Visibility,
} from "@mui/icons-material";
import type {
  ChangeTablePage,
} from "@fas/cpa-state-manager/redux/actions/table";
import { makeStylesTyped } from "@fas/cpa-cabinet-ui/lib/helpers";
import { BRANDS_TABLE } from "../../helpers/constants";
import {
  TableComponent,
  TablePagination,
} from "../TableComponent";
import CopyToClipboard, {
  type ChildProps as CopyToClipboardChildrenProps,
} from "../CopyToClipboard";
import type { Column } from "../TableComponent/types/TableComponent.types";
import type { ActiveBrandsPayloadItem } from "../../containers/ActiveBrands/types/ActiveBrands.types";

type Props = {
  data: ActiveBrandsPayloadItem[],
  total: number,
  page: number,
  pageSize: number,
  onChangeTablePage: (table: string, page: number) => ChangeTablePage,
  onCopyUrl: (value: string) => void,
  onOpenBannerDialog: (row: ActiveBrandsPayloadItem) => void,
}

type Classes = {
  button: string,
  headerCell: string,
  brandCell: string,
}

const useStyles: () => Classes = makeStylesTyped((theme: Theme) => ({
  button: {
    height: "auto",
    textTransform: "capitalize",
  },
  headerCell: {
    color: theme.palette.primary.main,
  },
  brandCell: {
    textAlign: "left",
  },
}));

const ActiveBrandsTable: StatelessFunctionalComponent<Props> = ({
  data,
  total,
  page,
  pageSize,
  onChangeTablePage,
  onCopyUrl,
  onOpenBannerDialog,
}: Props) => {
  const classes: Classes = useStyles();

  const columns: Array<Column<ActiveBrandsPayloadItem>> = [
    {
      field: "domain",
      label: "Brand name",
      key: "title",
      className: classes.brandCell,
    },
    {
      field: "vertical",
      label: "Vertical",
      key: "vertical",
    },
    {
      field: "niche",
      label: "Niche",
      key: "niche",
    },
    {
      field: "platform",
      label: "Platform",
      key: "platform",
    },
    {
      field: "url",
      label: "URL",
      key: "url",
      render: ({ row }): Node => (
        <CopyToClipboard
          copy={(): void => onCopyUrl(row.url)}
        >
          {({ copy }: CopyToClipboardChildrenProps): Element<Button> => (
            <Button
              variant="text"
              size="small"
              startIcon={<FileCopyOutlined />}
              onClick={copy}
              className={classes.button}
              data-testid="copy-btn"
            >
              Copy
            </Button>
          )}
        </CopyToClipboard>

      ),
    },
    {
      field: "image",
      label: "Banner",
      key: "image",
      render: ({ row }): Node => (
        <Button
          variant="text"
          size="small"
          startIcon={<Visibility />}
          onClick={(): void => onOpenBannerDialog(row)}
          className={classes.button}
          data-testid="banner-dialog-open-btn"
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <>
      <TableComponent
        classes={{ headerCell: classes.headerCell }}
        data={data}
        columns={columns}
      />
      <TablePagination
        page={page}
        pageSize={pageSize}
        count={total}
        onPageChange={(newPage: number) => {
          onChangeTablePage(BRANDS_TABLE, newPage);
        }}
      />
    </>
  );
};

export default ActiveBrandsTable;
